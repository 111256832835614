import {
  useBreakpointValue,
  VStack,
  Spinner,
  Flex,
  Box,
  Stack,
  Heading,
} from "@chakra-ui/react";
import { useUser } from "./lib/firebase";
import { ChooseRoom } from "./ChooseRoom";
import { Login } from "./LoginPage";
import HireMe from "./components/HireMe";

export default function Home() {
  const { user, loading, error } = useUser();

  const youtube = useBreakpointValue({
    base: {},
    md: { width: 560, height: 315 },
  });
  const headingSize = useBreakpointValue({
    base: "xl",
    md: "4xl",
  });

  if (error) return <div>{error.message}</div>;

  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"} bg={"gray.800"}>
      <VStack>
        <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
          <Box rounded={"lg"} bg={"gray.700"} boxShadow={"lg"} p={8}>
            <Heading fontSize={headingSize} textAlign={"center"} mb={6}>
              Dungeons and Dragons Infinity 🐉 🧙‍♂️ ♾️
            </Heading>
            {loading ? (
              <Flex justify={"center"}>
                <Spinner />
              </Flex>
            ) : user ? (
              <ChooseRoom />
            ) : (
              <Login />
            )}
          </Box>
        </Stack>
        {youtube && (
          <iframe
            {...youtube}
            src="https://www.youtube.com/embed/P2ZfzSAce6I"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        )}
        <Box p={10}>
          <HireMe />
        </Box>
      </VStack>
    </Flex>
  );
}
