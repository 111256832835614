import { useMemo, useState } from "react";
import {
  Grid,
  GridItem,
  VStack,
  Box,
  BoxProps,
  Text,
  Button,
} from "@chakra-ui/react";
import { Bubble } from "./Bubble";
import Input from "./Input";
import { useBreakpointValue } from "@chakra-ui/react";
import { useUser } from "../lib/firebase";
import { useGameState } from "../App";
import React, { useEffect } from "react";
import { useForceDmTurnCallback } from "../lib/callbacks";

export type ChatProps = BoxProps;

function hashMessages(messages: string[]) {
  const s = messages.join("");
  return s.split("").reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
}

function ForceDmTurn() {
  const [messagesHash, setMessageshash] = useState(67); // hash is a number, 67 is arbitary
  const [recentlyChangedMessage, setRecentlyChangedMessage] = useState(false);
  const { user } = useUser();
  const { players, messages, room } = useGameState();
  const forceDmTurn = useForceDmTurnCallback();

  const nextMessagesHash = useMemo(() => {
    return hashMessages(messages.map((m) => m.message));
  }, [messages]);

  useEffect(() => {
    if (nextMessagesHash !== messagesHash) {
      setMessageshash(nextMessagesHash);
      setRecentlyChangedMessage(true);
      setTimeout(() => {
        setRecentlyChangedMessage(false);
      }, 10000);
    }
  }, [messages, messagesHash, nextMessagesHash]);

  const DmCurrentlyThinking = room?.status === "THINKING";
  const singlePlayer = players && players.length === 1;
  const userIsSinglePlayer = singlePlayer && user!.displayName === players[0];
  const lastMessageWasFromDm = messages?.at(-1)?.entity === "dm";
  const literallyJustChangedMessage = nextMessagesHash !== messagesHash;
  if (
    literallyJustChangedMessage ||
    recentlyChangedMessage ||
    DmCurrentlyThinking ||
    !user ||
    !players ||
    userIsSinglePlayer ||
    !messages ||
    lastMessageWasFromDm
  ) {
    return <React.Fragment></React.Fragment>;
  }

  return <Button onClick={forceDmTurn}>Force DM Turn</Button>;
}

const statusMessages = {
  READY: "🧝 Waiting for player(s)...",
  THINKING: "🤖 AI thinking...",
};

function Bubbles() {
  const { messages, room } = useGameState();

  const margin = useBreakpointValue({ base: 0, md: 100 });

  //@ts-ignore
  const status = room ? statusMessages[room.status] : "";

  useEffect(() => {
    console.log({ messages });
  }, [messages]);

  return (
    <Box mb={margin}>
      <VStack alignContent={"start"} h={"100%"}>
        {messages.map(
          (
            {
              message,
              entity,
              username,
              stableDiffusionPrompt,
              stableDiffusionUrl,
            },
            index
          ) => (
            <Bubble
              key={index}
              message={message}
              orientation={entity === "dm" ? "dm" : "user"}
              user={username}
              stableDiffusionPrompt={stableDiffusionPrompt}
              stableDiffusionUrl={stableDiffusionUrl}
            />
          )
        )}
        <VStack>
          <Text fontSize={13}>Game Status: {status}</Text>
          <ForceDmTurn />
        </VStack>
      </VStack>
    </Box>
  );
}

export default function Chat(props: ChatProps) {
  const { user } = useUser();

  if (!user)
    return (
      <Box {...props} overflow={"scroll"} p={5}>
        <Bubbles />
      </Box>
    );

  return (
    <Box {...props}>
      <Grid
        templateAreas={`"chat"
        "input"`}
        gridTemplateRows={"1fr auto"}
        h={"100%"}
      >
        <GridItem area={"chat"} p={5} overflow={"scroll"}>
          <Bubbles />
        </GridItem>
        <GridItem area={"input"}>
          <Input />
        </GridItem>
      </Grid>
    </Box>
  );
}
