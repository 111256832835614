import { useEffect, useState } from "react";
import { Box, Image, BoxProps, Spinner } from "@chakra-ui/react";
import { storage } from "../lib/firebase";
import { ref, getDownloadURL } from "firebase/storage";
import { useGameState, useUIState } from "../App";

export type VizProps = BoxProps;

export default function Viz(props: VizProps) {
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const { room } = useGameState();
  const { vizUrl } = useUIState();

  useEffect(() => {
    const pathRef = ref(storage, vizUrl || room.viz);
    getDownloadURL(pathRef).then((imageUrl) => setImageUrl(imageUrl));
  }, [room, vizUrl]);

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      {...props}
    >
      {imageUrl ? <Image src={imageUrl} /> : <Spinner />}
    </Box>
  );
}
