import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  signOut as signOutFirebase,
} from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { getFirestore } from "firebase/firestore";
import { GoogleAuthProvider } from "firebase/auth";
import { getStorage } from "firebase/storage";

const app = initializeApp({
  apiKey: "AIzaSyB0f_2UXLZSFddzHFIQZdiEsFmRI1r7oR4",
  authDomain: "dnd-infinity.firebaseapp.com",
  projectId: "dnd-infinity",
  storageBucket: "dnd-infinity.appspot.com",
  messagingSenderId: "288669529467",
  appId: "1:288669529467:web:0589fae340dd9a83aff9de",
  measurementId: "G-1MX409EQXS",
});
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage();

async function signIn() {
  // https://firebase.google.com/docs/auth/web/auth-state-persistence
  const provider = new GoogleAuthProvider();
  signInWithPopup(auth, provider);
}

function useUser() {
  const [user, loading, error] = useAuthState(auth);
  return { user, loading, error };
}

function signOut() {
  signOutFirebase(auth);
}

export { app, auth, db, signIn, signOut, storage, useUser };
