import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db, useUser } from "./firebase";
import { useCallback } from "react";
import { useRoomId } from "./gameState";
import { useUIState } from "../App";
import { api } from "./api";

export function usePostCallback() {
  const { user } = useUser();
  const roomId = useRoomId();
  return useCallback(
    async (message: string) => {
      const messageData = {
        message: message,
        created: serverTimestamp(),
        entity: "user",
        username: user?.displayName,
        roomId: roomId,
      };
      await addDoc(collection(db, "rooms", roomId, "messages"), messageData);
    },
    [roomId, user]
  );
}

export function useResetVisualizationCallback() {
  const { setVizPrompt, setVizUrl } = useUIState();
  return useCallback(() => {
    setVizPrompt(undefined);
    setVizUrl(undefined);
  }, [setVizPrompt, setVizUrl]);
}

export function useForceDmTurnCallback() {
  const roomId = useRoomId();
  return useCallback(async () => {
    await api.get("forceDmTurn", { searchParams: { roomId } });
  }, [roomId]);
}
