import { extendTheme, type ThemeConfig } from '@chakra-ui/react'

const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
}

const components = {
    Button: {
        baseStyle: {
            color: 'white',
            background: 'blue.500',
        },
    }
}

const theme = extendTheme({ config, components })

export default theme