import { useCallback } from "react";
import { Avatar, Text, Box, Flex } from "@chakra-ui/react";
import { useUIState } from "../App";

const userBubble = {
  bg: "blue.500",
  color: "white",
  sx: {
    borderBottomRightRadius: "0",
  },
};

const dmBubble = {
  bg: "gray.500",
  sx: {
    borderBottomLeftRadius: "0",
  },
};

export function Bubble({
  message,
  orientation,
  user,
  stableDiffusionPrompt,
  stableDiffusionUrl,
}: {
  message: string;
  orientation: "user" | "dm";
  user?: string;
  stableDiffusionPrompt?: string;
  stableDiffusionUrl?: string;
}) {
  const disabledTooltip = !stableDiffusionPrompt || !stableDiffusionUrl;
  const { setVizPrompt, setVizUrl } = useUIState();
  const setViz = useCallback(() => {
    if (disabledTooltip) return;
    setVizPrompt(stableDiffusionPrompt);
    setVizUrl(stableDiffusionUrl);
  }, [
    stableDiffusionPrompt,
    stableDiffusionUrl,
    setVizPrompt,
    setVizUrl,
    disabledTooltip,
  ]);
  return (
    <Flex
      w={"100%"}
      alignItems={"flex-end"}
      justifyContent={orientation === "dm" ? "start" : "end  "}
      gap={3}
      onClick={setViz}
    >
      {orientation === "dm" && <Avatar size={"sm"} name={"Dungeon Master"} />}
      <Box
        pt={2}
        pb={2}
        pl={4}
        pr={4}
        fontWeight={"semibold"}
        rounded={"xl"}
        {...(orientation === "user" ? userBubble : dmBubble)}
      >
        <Text>{message}</Text>
      </Box>
      {orientation === "user" && <Avatar size={"sm"} name={user} />}
    </Flex>
  );
}
