import AppGrid from "./components/AppGrid";
import NavBar from "./components/NavBar";
import Viz from "./components/Viz";
import Chat from "./components/Chat";
import GameInfo from "./components/GameInfo";
import { createContext, useContext, useEffect, useState } from "react";
import {
  useMessages,
  useRoom,
  useRoomId,
  useQuests,
  usePlayers,
} from "./lib/gameState";
import CenterSpinner from "./components/CenterSpinner";
import type { IRoom, IMessage, IQuest } from "./lib/interfaces";
import { Box } from "@chakra-ui/react";

// Here, we package all room state into a context so we avoid multiple
// subscriptions to the same data

//@ts-ignore
const GameStateContext = createContext<{
  room: IRoom;
  roomId: string;
  messages: IMessage[];
  quests: IQuest[];
  players: string[];
}>();

export function useGameState() {
  const context = useContext(GameStateContext);
  if (!context)
    throw new Error("useGameState must be used within a GameStateProvider");
  return context;
}

//@ts-ignore
const UIState = createContext<{
  vizPrompt?: string;
  setVizPrompt: (prompt: string | undefined) => void;
  vizUrl?: string;
  setVizUrl: (url: string | undefined) => void;
}>();

export function useUIState() {
  const context = useContext(UIState);
  if (!context)
    throw new Error("useUIState must be used within a UIStateProvider");
  return context;
}

export default function App() {
  // UI state
  const [vizPrompt, setVizPrompt] = useState<string | undefined>(undefined);
  const [vizUrl, setVizUrl] = useState<string | undefined>(undefined);

  // DB state
  const roomId = useRoomId();
  const { room, loading: roomLoading, error: roomErrors } = useRoom();
  const {
    messages,
    loading: messagesLoading,
    error: messagesErrors,
  } = useMessages();
  const { quests, loading: questsLoading, error: questsErrors } = useQuests();
  const players = usePlayers();

  useEffect(() => {
    const title = `Dungeons & Dragons Infinity️`;
    if (!room) {
      window.document.title = title;
    } else {
      document.title = `${title} :: ${room.roomId}`;
    }
  }, [room]);

  if (roomLoading || messagesLoading || questsLoading) {
    return <CenterSpinner />;
  }

  if (roomErrors || messagesErrors || questsErrors || !room) {
    return (
      <Box>
        {JSON.stringify({ roomErrors, messagesErrors, questsErrors, room })}
      </Box>
    );
  }

  return (
    <GameStateContext.Provider
      value={{
        room,
        roomId,
        messages,
        quests,
        players,
      }}
    >
      <UIState.Provider value={{ vizPrompt, setVizPrompt, vizUrl, setVizUrl }}>
        <AppGrid
          nav={NavBar}
          viz={Viz}
          chat={Chat}
          gameInfo={GameInfo}
          h={"100vh"}
          w={"full"}
        />
      </UIState.Provider>
    </GameStateContext.Provider>
  );
}
