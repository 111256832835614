import { Text, FormControl, Input, Stack, Button } from "@chakra-ui/react";
import { signOut } from "./lib/firebase";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";

export function ChooseRoom() {
  const navigate = useNavigate();
  return (
    <Formik
      validate={(values) => {
        if (!values.roomId) return { roomId: "Cannot be blank!" };
      }}
      initialValues={{ roomId: "" }}
      onSubmit={(values) => {
        navigate(`/room/${encodeURIComponent(values.roomId)}`);
      }}
    >
      {({
        values,
        handleChange,
        isSubmitting,
        errors,
        touched,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormControl id="room">
            <Input
              id={"roomId"}
              onChange={handleChange}
              value={values.roomId}
              mb={4}
              size={"lg"}
              type="text"
              placeholder={"Room ID"}
            />
            {touched.roomId && errors.roomId && (
              <Text fontWeight={"semibold"} color={"red.500"}>
                {errors.roomId}
              </Text>
            )}
            <Stack spacing={4}>
              <Button
                loadingText="Submitting"
                size="lg"
                bg={"blue.400"}
                color={"white"}
                _hover={{
                  bg: "blue.500",
                }}
                disabled={isSubmitting}
                type={"submit"}
              >
                Enter a new reality 🚪
              </Button>
              <Button size={"lg"} onClick={signOut}>
                Log out
              </Button>
            </Stack>
          </FormControl>
        </form>
      )}
    </Formik>
  );
}
