import { useState } from "react";
import { Button, Input as ChakraInput, HStack } from "@chakra-ui/react";
import { Formik } from "formik";
import { usePostCallback } from "../lib/callbacks";
import { useGameState } from "../App";

export default function Input() {
  const [recentlySubmitted, setRecentlySubmitted] = useState<boolean>(false);
  const { room } = useGameState();
  const post = usePostCallback();
  const canSubmit = room?.status === "READY" && !recentlySubmitted;
  return (
    <Formik
      validate={(values) => {
        if (!values.message) {
          return { message: "Cannot be blank!" };
        }
      }}
      initialValues={{ message: "" }}
      onSubmit={(values) => {
        if (!values.message) return; // don't send empty messages to server
        setRecentlySubmitted(true);
        post(values.message);
        values.message = "";
        setTimeout(() => setRecentlySubmitted(false), 1000);
      }}
    >
      {({ values, handleChange, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <HStack p={5} bg={"gray.700"}>
            <ChakraInput
              id={"message"}
              onChange={handleChange}
              value={recentlySubmitted ? "" : values.message}
              variant={"filled"}
              placeholder={"What would you like to do?"}
              bg={"gray.800"}
              shadow={"2xl"}
              borderWidth={2}
              rounded={"xl"}
              borderColor={"gray.600"}
            />
            <Button
              type={"submit"}
              rounded={"xl"}
              shadow={"sm"}
              disabled={isSubmitting || !canSubmit}
            >
              Do it ⚔️
            </Button>
          </HStack>
        </form>
      )}
    </Formik>
  );
}
