import React from "react";
import { MdEmail } from "react-icons/md";
import { FiLink } from "react-icons/fi";
import {
  Text,
  Center,
  Avatar,
  Button,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Flex,
  HStack,
} from "@chakra-ui/react";

function emailMe() {
  const emailAddress = "jeremy.adams.fisher@gmail.com";
  const subject = "DnD Infinity / Hiring";
  const body = `Hi Jeremy,

I saw DnD Infinity and I wanted to hire you immmediately for my awesome AI company.

Thanks!`;
  const href = `mailto:${emailAddress}?subject=${
    encodeURIComponent(subject) || ""
  }&body=${encodeURIComponent(body) || ""}`;
  window.open(href);
}

export default function HireMe() {
  const { onOpen, onClose, isOpen } = useDisclosure();
  return (
    <React.Fragment>
      <Link onClick={onOpen}>Hire me 👨‍💻</Link>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Flex pt={5} pb={5} flexDir={"column"} gap={5}>
              <Center>
                <Link href={"http://jeremyf.net"}>
                  <Avatar
                    name={"Jeremy Fisher"}
                    size={"3xl"}
                    src={"https://www.jeremyafisher.com/images/prof.jpg"}
                    shadow={"md"}
                    borderWidth={1}
                    borderColor={"gray.600"}
                    transition={"all 0.2s ease"}
                    _hover={{
                      transform: "scale(1.05)",
                      shadow: "2xl",
                    }}
                  />
                </Link>
              </Center>
              <Text>Hello there! 👋</Text>
              <Text>
                I'm <b>Jeremy Fisher</b>, a Machine Learning & Software Engineer
                from San Francisco 🌁
              </Text>
              <Text>I'm currently looking for a job involving ML/NLP 🤖</Text>
              <Text>
                I'm good at full-stack and machine learning product, including
                data ops 🔗 I wrote this game myself 😅
              </Text>
              <Button
                leftIcon={<MdEmail />}
                mt={5}
                colorScheme={"blue"}
                onClick={emailMe}
              >
                Email me
              </Button>
              <Link href={"http://jeremyf.net/resume.pdf"} isExternal={true}>
                <HStack w={"100%"} display={"flex"} justifyContent={"center"}>
                  <FiLink />
                  <Text>Check out my resume </Text>
                </HStack>
              </Link>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </React.Fragment>
  );
}
