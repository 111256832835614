import {
  HStack,
  Flex,
  Text,
  Avatar,
  Button,
  useBreakpointValue,
  AvatarProps,
} from "@chakra-ui/react";
import { signIn, signOut, useUser } from "../lib/firebase";

import { useGameState } from "../App";

function UserView(props: AvatarProps) {
  const { user } = useUser();
  return user ? (
    <HStack dir={"col"}>
      <Button onClick={signOut}>Log out</Button>
      <Avatar {...props} name={user.displayName || ""} />
    </HStack>
  ) : (
    <Button onClick={signIn}>Sign in</Button>
  );
}

function MobileNav() {
  const { roomId } = useGameState();
  return (
    <Flex
      minH={"60px"}
      py={{ base: 2 }}
      px={{ base: 4 }}
      borderBottomWidth={1}
      align={"center"}
    >
      <Flex
        flex={{ base: 1 }}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Text fontFamily={"heading"}>🐉🧙‍♂️♾️</Text>
        <Text fontWeight={"bold"}>{roomId}</Text>
        <UserView display={"none"} />
      </Flex>
    </Flex>
  );
}

function DesktopNav() {
  const { roomId } = useGameState();
  return (
    <Flex
      minH={"60px"}
      py={{ base: 2 }}
      px={{ base: 4 }}
      borderBottomWidth={1}
      align={"center"}
    >
      <Flex
        flex={{ base: 1 }}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Text fontFamily={"heading"}>Dungeons & Dragons Infinity 🐉🧙‍♂️♾️</Text>
        <Text fontWeight={"bold"}>Room: {roomId}</Text>
        <UserView size={"md"} />
      </Flex>
    </Flex>
  );
}

export default function NavBar() {
  const navbar = useBreakpointValue({
    base: <MobileNav />,
    md: <DesktopNav />,
  });
  return navbar ? navbar : <MobileNav />;
}
