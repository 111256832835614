import { Stack, Button } from "@chakra-ui/react";
import { FaGoogle } from "react-icons/fa";
import { signIn } from "./lib/firebase";

export function Login() {
  return (
    <Stack spacing={4}>
      <Button
        loadingText="Submitting"
        size="lg"
        bg={"blue.400"}
        color={"white"}
        _hover={{
          bg: "blue.500",
        }}
        leftIcon={<FaGoogle />}
        onClick={() => signIn()}
        shadow={"2xl"}
      >
        Sign in
      </Button>
    </Stack>
  );
}
