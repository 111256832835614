import {
  Button,
  Box,
  BoxProps,
  Badge,
  Text,
  UnorderedList,
  ListItem,
  Heading,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import React from "react";
import { useGameState, useUIState } from "../App";
import { useResetVisualizationCallback } from "../lib/callbacks";

export type GameInfoProps = BoxProps;

function VizPrompt() {
  const { room } = useGameState();
  const { vizPrompt } = useUIState();
  const resetVisualization = useResetVisualizationCallback();
  return (
    <AccordionItem>
      <AccordionButton>
        <Heading flex={"1"} textAlign={"left"} size={"sm"}>
          🎨️ Stable Diffusion Prompt
        </Heading>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4}>
        {vizPrompt ? (
          <React.Fragment>
            <Text p={5}>
              <b>Previous Prompt:</b> {vizPrompt}
            </Text>
            <Button w="full" onClick={resetVisualization}>
              Visualize Most Recent Prompt
            </Button>
          </React.Fragment>
        ) : (
          <Text p={5}>{room.prompt}</Text>
        )}
      </AccordionPanel>
    </AccordionItem>
  );
}

function Quests() {
  const { quests } = useGameState();
  return (
    <AccordionItem>
      <AccordionButton>
        <Heading flex={"1"} textAlign={"left"} size={"sm"}>
          📖️ Quests <Badge>{quests.length}</Badge>{" "}
        </Heading>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4}>
        <UnorderedList p={5}>
          {quests.map((quest, index) => (
            <ListItem key={index}>{quest.name}</ListItem>
          ))}
        </UnorderedList>
      </AccordionPanel>
    </AccordionItem>
  );
}

export default function GameInfo(props: BoxProps) {
  return (
    <Box {...props}>
      <Accordion defaultIndex={[0]} allowMultiple={true}>
        <Quests />
        <VizPrompt />
      </Accordion>
    </Box>
  );
}
