// These functions are meant to be called once the App component has mounted, and
// mainly assume that the router is already set up to provide a game ID

import { orderBy, query, collection, doc, setDoc, serverTimestamp } from "firebase/firestore"; 
import { useCollectionData, useDocumentData } from "react-firebase-hooks/firestore";
import { db } from "./firebase";
import { IMessage, IRoom, IQuest } from "./interfaces";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

export function useRoomId() {
  const { roomId } = useParams();
  return roomId as string;
}

export function useRoom(): { room: IRoom | null, loading: boolean, error: any} {
  const roomId = useRoomId();
  const roomRef = doc(db, "rooms", roomId);
  const [roomData, loading, error] = useDocumentData(roomRef);
  useEffect( () => {
    const initializeRoom = async () => { 
      if (!roomData && !loading && !error) {
        const roomDoc: IRoom = {
          roomId: roomId,
          viz: "8a7a582e-7623-47f7-91ca-615d8f68d8bf.jpg",
          created: serverTimestamp(),
          status: "THINKING",
          prompt: "Digital painting of a shady tavern, a cloaked figure, dnd, dungeons and dragons, by artgerm and greg rutkowski"
        }
        await setDoc(roomRef, roomDoc);
      }
    }
    initializeRoom()
  }, [roomData, loading, error, roomRef, roomId]);
  const room = roomData ? roomData as IRoom : null;
  return {room, loading, error};
}

export function useMessages(): { messages: IMessage[], loading: boolean, error: any} {
  const roomId = useRoomId();
  const q = query(collection(db, "rooms", roomId, "messages"), orderBy("created"));
  const [messageData, loading, error] = useCollectionData(q);
  const messages = messageData ? messageData as IMessage[] : [];
  return {messages, loading, error};
}

export function useQuests() {
  const roomID = useRoomId();
  const q = query(collection(db, "rooms", roomID, "quests"), orderBy("created"));
  const [questData, loading, error] = useCollectionData(q);
  const quests = questData ? questData as IQuest[] : [];
  return {quests, loading, error};
}

export function usePlayers() {
  const messages = useMessages();
  const players = useMemo(() => {
    if (!messages) return [];
    return messages.messages.filter(m => m.entity !== "dm" && m.username).map(m => m.username as string);
  }, [messages])
  return players;
}