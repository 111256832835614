import React from "react";
import {
  BoxProps,
  Grid,
  GridItem,
  GridProps,
  useBreakpointValue,
} from "@chakra-ui/react";
import type { VizProps } from "./Viz";
import type { ChatProps } from "./Chat";
import type { GameInfoProps } from "./GameInfo";
import CenterSpinner from "./CenterSpinner";

interface AppGridProps extends GridProps {
  nav: React.FC<BoxProps>;
  viz: React.FC<VizProps>;
  chat: React.FC<ChatProps>;
  gameInfo: React.FC<GameInfoProps>;
}

const cell = {
  borderWidth: 1,
  rounded: "md",
};

const styles = {
  base: {
    grid: {
      templateAreas: `"nav"
          "viz"
          "gameinfo"
          "chat"
          `,
      gridTemplateRows: "1fr",
      gridTemplateColumns: "1fr",
      gap: 5,
    },
    nav: {},
    viz: { w: "full", minW: "100%" },
    gameInfo: { h: "full" },
    chat: {},
  },
  md: {
    grid: {
      templateAreas: `"nav nav nav nav"
      ". viz chat ."
      ". gameinfo chat ."
      ". . . ."`,
      gridTemplateRows: "60px 500px 1fr 0px",
      gridTemplateColumns: "0px 500px 1fr 0px",
      gridColumnGap: 5,
      gridRowGap: 5,
    },
    nav: { mb: 5 },
    viz: { h: "full", ...cell },
    gameInfo: { h: "full" },
    chat: { ...cell, h: "full" },
  },
};

export default function AppGrid(props: AppGridProps) {
  const { nav: Nav, viz: Viz, chat: Chat, gameInfo: GameInfo, ...rest } = props;

  const style = useBreakpointValue(styles);

  if (!style) return <CenterSpinner />;

  const { grid, nav, viz, gameInfo, chat } = style;

  return (
    <Grid {...grid} {...rest}>
      <GridItem area={"nav"}>
        <Nav {...nav} />
      </GridItem>
      <GridItem area={"viz"}>
        <Viz {...viz} />
      </GridItem>
      <GridItem area={"gameinfo"}>
        <GameInfo {...gameInfo} />
      </GridItem>
      <GridItem area={"chat"}>
        <Chat {...chat} />
      </GridItem>
    </Grid>
  );
}
